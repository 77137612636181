import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { Link, graphql, useStaticQuery } from "gatsby";
import unified from "unified";
import markdown from "remark-parse";
import html from "remark-html";
import Layout from "../components/layout";
import ProductListItem from "../components/ProductListItem";
import { useMediaQuery } from "react-responsive";
import HeaderBottom from "../components/HeaderBottom";

const BlogsPageTemplate = ({ data, pageContext, location }) => {
  let quickLinkTags = data.tags.frontmatter.tags;

  console.log("data is: ", data);
  const blogs = data.allMarkdownRemark.edges;
  const siteTitle = data.site.siteMetadata.title;
  const siteUrl = data.site.siteMetadata.siteUrl;

  const {
    currentPage,
    numPages,
    blogsPerPage,
    skip,
    limit,
    slug,
    regexp,
    type,
    pageid,
  } = pageContext;
  const totalBlogs = data.allMarkdownRemark.totalCount;
  const realNumPages = Math.ceil(totalBlogs / blogsPerPage);
  const isFirst = currentPage === 1;
  const isLast = currentPage === realNumPages;
  const prevPage =
    slug + (currentPage - 1 === 1 ? "/" : "/" + (currentPage - 1).toString());
  const nextPage = slug + "/" + (currentPage + 1).toString();

  // Based on brand or tag, obtain object to get: pageName, pageDescription, pageImageHeader
  let pageName = "";
  let pageDescription = "";
  let pageImageHeader = "";
  console.log(pageid, data, `${type}`, data[`${type}`]);
  const pageObject = data[`${type}`]
    ? data[`${type}`].frontmatter[`${type}`].filter((e) => e.id == pageid)[0]
    : null;
  console.log("pageobj", pageObject);
  if (pageObject) {
    pageName = pageObject.name;
    pageDescription = pageObject.description;
    pageImageHeader = pageObject.imageHeader;
  }

  console.log("loc", location, location.origin);
  console.log("blogs", data, blogs, pageName, regexp);
  console.log(
    "PAGIN",
    skip,
    limit,
    currentPage,
    numPages,
    realNumPages,
    totalBlogs,
    blogsPerPage,
    isFirst,
    isLast
  );

  console.log("pageContext", pageContext);

  // useEffect(() => {
  //   console.log("testomani", window.$("#prodect-modal"));
  // });
  const isMoreThan345 = useMediaQuery({ query: "(min-width: 345px)" });
  const is789 = useMediaQuery({ query: "(max-width: 789px)" });
  const PAGEINFOSHORTLENGTH = is789 ? 195 : 320;

  let pageDescriptionShort =
    pageDescription.length > PAGEINFOSHORTLENGTH
      ? pageDescription.substring(0, PAGEINFOSHORTLENGTH)
      : "";
  const [pageInfoReadMore, setPageInfoReadMore] = useState(
    pageDescriptionShort ? false : true
  );
  console.log("pageInfoReadMore", pageInfoReadMore);

  const twitterIndex = Math.floor(Math.random() * blogs.length);

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        bodyAttributes={{
          class: "site-layout--horizontal scroll-is--active",
        }}
      />
      <HeaderBottom />
      <main class="site-content" id="wrapper">
        <div class="widget-area widger-area--before-loop">
          <div class="widget widget-carousel slick-slider">
          {blogs &&
            blogs.slice(0,3).map((blog, index) => {
              console.log("twitter index", twitterIndex, index);
              return (
                <article class="widget-carousel__item post slick-slide">
              <div class="post__thumbnail">
                <img
                src={
                  blog.node.frontmatter.mainImage
                    ? blog.node.frontmatter.mainImage
                    : "/assets/img/samples/widget-posts-carousel-img-01.jpg"
                }
                  alt=""
                />
              </div>
              <div class="post__body">
                <ul class="post__cats list-unstyled">
                  <li class="post__cats-item color-primary">
                    <a href="#">{blog.node.frontmatter.category}</a>
                  </li>
                </ul>
                <h2 class="post__title">
                <Link to={blog.node.fields.slug}>
                {blog.node.frontmatter.title}
                          </Link>
                </h2>
                <ul class="post__meta list-unstyled">
                  <li class="post__meta-item post__meta-item--date">
                  <a href="#">{blog.node.frontmatter.date}</a>
                  </li>
                </ul>
              </div>
            </article>
              );
            })}
          </div>
        </div>
        <div class="content blog-layout--style-4">
          {blogs &&
            blogs.map((blog, index) => {
              console.log("twitter index", twitterIndex, index);
              return (
                <>
                  <article class="post has-post-thumbnail ">
                    <div class="post__thumbnail">
                      <img
                        src={
                          blog.node.frontmatter.mainImage
                            ? blog.node.frontmatter.mainImage
                            : "/assets/img/samples/news-v4-post-img-01.jpg"
                        }
                        alt=""
                      />
                    </div>
                    <div class="post__body">
                      <div class="post__header">
                        <ul class="post__cats list-unstyled">
                          <li class="post__cats-item color-warning">
                            {blog.node.frontmatter.category}
                          </li>
                        </ul>
                        <h2 class="post__title h4">
                          <Link to={blog.node.fields.slug}>
                            {blog.node.frontmatter.title}
                          </Link>
                        </h2>
                        <ul class="post__meta list-unstyled">
                          <li class="post__meta-item post__meta-item--date">
                            <a href="#">{blog.node.frontmatter.date}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="post__excerpt">{blog.node.excerpt}</div>
                    </div>
                  </article>
                  {twitterIndex == index && (
                    <div class="widget widget-loop widget-twitter">
                      <div class="widget-twitter__icon">
                        <i class="fab fa-twitter">&nbsp;</i>
                      </div>
                      <div class="widget-twitter__body">
                        <div class="widget-twitter__message">
                          Watch{" "}
                          <a href="https://twitter.com/hashtag/TheGameAwards?src=hashtag_click">
                            #TheGameAwards
                          </a>{" "}
                          live on Twitter, Thursday starting at 6:30 PM ET /
                          3:30 PM PT. Set a reminder below:
                        </div>
                        <a href="#" class="widget-twitter__date">
                          2 Hours Ago
                        </a>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </main>
    </Layout>
  );
};

export default BlogsPageTemplate;

export const pageQuery = graphql`
  query BlogsListQuery($skip: Int!, $limit: Int!, $regexp: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { type: { eq: "blog" }, tag: { regex: $regexp } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          frontmatter {
            title
            date(formatString: " DD MMMM YYYY", locale: "id", fromNow: true)
            category
            mainImage
          }
          fields {
            slug
          }
        }
      }
    }
    brands: markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        brands {
          id
          name
          description
          imageHeader
        }
      }
    }
    tags: markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        tags {
          id
          name
          description
          imageHeader
        }
      }
    }
  }
`;
